// src/store/cameraSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { Vector3 } from "three";

const cameraSlice = createSlice({
  name: "camera",
  initialState: {
    display: "mobile",
    cameraPosition: new Vector3(11.27, 0.35, 9.9),
    cameraTarget: new Vector3(0, 0, 0),
    activeScene: "homeScene",
    loading: "true",

    homeScenePosition: [0, 0, 0],
    homeSceneCameraPosition: new Vector3(11.27, 0.35, 9.9),
    homeSceneCameraTarget: new Vector3(0, 0, 0),

    skillsScenePosition: [-50, 0, -50],
    skillsSceneCameraPosition: new Vector3(-50, 5, -30),
    skillsSceneCameraTarget: new Vector3(-50, 0, -50),

    projectsScenePosition: [50, 0, -50],
    projectsSceneCameraPosition: new Vector3(50, 10, -30),
    projectsSceneCameraTarget: new Vector3(50, 0, -50),
  },
  reducers: {
    setCameraPosition: (state, action) => {
      state.cameraPosition = action.payload;
    },

    setCameraTarget: (state, action) => {
      state.cameraTarget = action.payload;
    },
    setActiveScene: (state, action) => {
      state.activeScene = action.payload;
    },

    setDisplay: (state, action) => {
      state.display = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setCameraPosition,
  setActiveScene,
  setCameraTarget,
  setDisplay,
  setLoading,
} = cameraSlice.actions;

export default cameraSlice.reducer;
