import { Vector3 } from "three";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveScene,
  setCameraPosition,
  setCameraTarget,
} from "../store/cameraSlice";
import { setNavigationVisibility } from "../store/navigationSlice";

const Navigation = ({}) => {
  const dispatch = useDispatch();
  const visibility = useSelector((state) => state.navigation.visibility);
  const skillsSceneCameraPosition = useSelector(
    (state) => state.camera.skillsSceneCameraPosition
  );
  const skillsSceneCameraTarget = useSelector(
    (state) => state.camera.skillsSceneCameraTarget
  );
  const projectsSceneCameraPosition = useSelector(
    (state) => state.camera.projectsSceneCameraPosition
  );
  const projectsSceneCameraTarget = useSelector(
    (state) => state.camera.projectsSceneCameraTarget
  );
  const homeSceneCameraPosition = useSelector(
    (state) => state.camera.homeSceneCameraPosition
  );
  const homeSceneCameraTarget = useSelector(
    (state) => state.camera.homeSceneCameraTarget
  );
  const cameraPosition = useSelector((state) => state.camera.cameraPosition);
  return (
    <div id="navigation" className={`navigation ${visibility}`}>
      <button
        className="navigation-btn"
        onClick={() => {
          dispatch(setCameraPosition(homeSceneCameraPosition));
          dispatch(setCameraTarget(new Vector3(...homeSceneCameraTarget)));
          dispatch(setNavigationVisibility("invisible"));
          dispatch(setActiveScene("homeScene"));
          console.log(homeSceneCameraPosition);
        }}
      >
        Home
      </button>
      <button
        className="navigation-btn"
        onClick={() => {
          dispatch(
            setCameraPosition(new Vector3(...skillsSceneCameraPosition))
          );
          dispatch(setCameraTarget(new Vector3(...skillsSceneCameraTarget)));
          dispatch(setActiveScene("skillsScene"));
        }}
      >
        Skills
      </button>
      <button
        className="navigation-btn"
        onClick={() => {
          dispatch(
            setCameraPosition(new Vector3(...projectsSceneCameraPosition))
          );
          dispatch(setCameraTarget(new Vector3(...projectsSceneCameraTarget)));
          dispatch(setActiveScene("projectsScene"));
        }}
      >
        Projects
      </button>
    </div>
  );
};
export default Navigation;
