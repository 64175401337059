import { createRoot } from "react-dom/client";
import "./style.css";
import { Provider } from "react-redux";
import store from "./store/store";
import ReactApp from "./reactapp";
import { Landing } from "./components";

const root = createRoot(document.querySelector("#root"));

root.render(
  <Provider store={store}>
    <ReactApp />
  </Provider>
);
