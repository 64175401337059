import {
  Environment,
  OrbitControls,
  PerspectiveCamera,
} from "@react-three/drei";
import { lazy, useEffect, useRef } from "react";
import { Perf } from "r3f-perf";
import { Vector3 } from "three";
import { useSelector } from "react-redux";
import { Suspense } from "react";
import { Landing } from "./components";
// Lazy-loaded scene components
const HomeScene = lazy(() => import("./scenes/HomeScene"));
const SkillsScene = lazy(() => import("./scenes/SkillsScene"));
const ProjectsScene = lazy(() => import("./scenes/ProjectsScene"));

const App = () => {
  const cameraRef = useRef();
  const orbitControlRef = useRef();

  // Get camera position and target from the Redux store
  const cameraPosition = useSelector((state) => state.camera.cameraPosition);
  const cameraTarget = useSelector((state) => state.camera.cameraTarget);

  // Update camera position and target on changes to cameraPosition and cameraTarget
  useEffect(() => {
    cameraRef.current.position.set(...cameraPosition);
    orbitControlRef.current.target.copy(new Vector3(...cameraTarget));
  }, [cameraPosition, cameraTarget]);
  return (
    <>
      {/* Performance monitoring tool */}
      {/* <Perf position="top-left" /> */}

      {/* Environment component for setting up lighting and background */}
      <Environment resolution={128} files={"./images/dawn.hdr"} />
      <ambientLight intensity={0.8} color={"red"} />

      {/* OrbitControls for camera movement */}
      <OrbitControls
        ref={orbitControlRef}
        // makeDefault
        target={[0, 0, 0]}
        dampingFactor={0.1}
      />
      <PerspectiveCamera
        ref={cameraRef}
        makeDefault
        far={30}
        near={2}
        minZoom={2}
      />
      {/* Lazy-loaded scene components */}
      <HomeScene />

      <SkillsScene />
      <ProjectsScene />
    </>
  );
};
export default App;
