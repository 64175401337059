import { AiOutlineClose, AiFillPhone, AiOutlineMail } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { setAboutmeVisibility } from "../store/aboutmeSlice";
import aboutMe from "../data/aboutme";
import "./AboutMeInformation.css";
function AboutMeInformation() {
  const visibility = useSelector((state) => state.aboutme.visibility);
  const disoatch = useDispatch();
  return (
    <div id="aboutMeInformation" className={`about-div ${visibility}`}>
      <div className="about">
        <div className="about-img">
          <img
            className="portraite-image"
            src="../images/keivan.png"
            alt="Keivan's portfolio"
          />
          <a className="btn resume-btn" href="/resume.pdf" target="_blank">
            My Resume
          </a>
          <div className="about-contact">
            <p className="email">
              <span>
                <AiOutlineMail />
              </span>
              Kaze0017@algonquinlive.com
            </p>
          </div>
        </div>
        <div className="about-inf">
          <div className="about-content">
            <h1>Keivan Kazemi</h1>
            <p>{aboutMe.intro}</p>
            <h2>skills</h2>
            <p>{aboutMe.skills}</p>
            <h2>Exploring New Horizons</h2>
            <p>{aboutMe.exploringNewHorizons}</p>
            <h2>education</h2>
            <p>{aboutMe.education}</p>
            <h2>work experience</h2>
            <p>{aboutMe.workExperience}</p>
          </div>
        </div>
      </div>

      <button
        className="btn close-btn"
        onClick={(e) => {
          disoatch(setAboutmeVisibility("invisible"));
        }}
      >
        <AiOutlineClose />
      </button>
    </div>
  );
}
export default AboutMeInformation;
