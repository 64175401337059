import { useFrame, useLoader } from "@react-three/fiber";
import { useState } from "react";
import { TextureLoader } from "three";
import { Text, useCursor } from "@react-three/drei";

const handleClick = (lable) => {
  lable.toLowerCase() === "websites"
    ? document
        .querySelector(`#websitesInformation`)
        .classList.remove("invisible")
    : null;
  lable.toLowerCase() === "videos"
    ? document.querySelector(`#videosInformation`).classList.remove("invisible")
    : null;

  lable.toLowerCase() === "photos"
    ? document.querySelector(`#photosInformation`).classList.remove("invisible")
    : null;

  lable.toLowerCase() === "arts"
    ? document.querySelector(`#artsInformation`).classList.remove("invisible")
    : null;
};

const ProjectsDisplayCube = ({
  images,
  refrence,
  cubePosition,
  args,
  rotation,
  lable,
}) => {
  const [hovered, setHovered] = useState(false);
  useCursor(hovered);
  const texture_0 = useLoader(TextureLoader, images[0]);
  const texture_1 = useLoader(TextureLoader, images[1]);
  const texture_2 = useLoader(TextureLoader, images[2]);
  const texture_3 = useLoader(TextureLoader, images[3]);
  const texture_4 = useLoader(TextureLoader, images[4]);
  const texture_5 = useLoader(TextureLoader, images[5]);
  rotation = [Math.random(), Math.random(), Math.random()];
  let textPosition = cubePosition.map((i) => i);
  textPosition[1] = 4;

  useFrame((state, delta) => {
    refrence.current.rotation.x += delta * 0.1;
    refrence.current.rotation.y += delta * 0.1;
    refrence.current.rotation.z += delta * 0.1;
  });
  return (
    <>
      <group
        position={cubePosition}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
        onClick={() => {
          handleClick(lable);
        }}
      >
        <Text
          position={window.innerWidth < 700 ? [3, 0, 0] : [0, 3, 0]}
          rotation={window.innerWidth < 700 ? [0, 0, -Math.PI / 2] : [0, 0, 0]}
          fontSize={0.5}
        >
          {lable}
        </Text>
        <mesh ref={refrence} images={images} rotation={rotation}>
          <boxGeometry args={args} />
          <meshBasicMaterial attach="material-0" map={texture_0} />
          <meshBasicMaterial attach="material-1" map={texture_1} />
          <meshBasicMaterial attach="material-2" map={texture_2} />
          <meshBasicMaterial attach="material-3" map={texture_3} />
          <meshBasicMaterial attach="material-4" map={texture_4} />
          <meshBasicMaterial attach="material-5" map={texture_5} />
        </mesh>
      </group>
    </>
  );
};
export default ProjectsDisplayCube;
