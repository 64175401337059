const aboutMe = {
  intro:
    "I am an accomplished Interactive Media Design student with a solid foundation in web development, UX/UI design, and graphic design. I am highly motivated and passionate about creating engaging digital experiences. With a creative problem-solving mindset and exceptional time-management skills, I excel both as an independent worker and a collaborative team member. I am set to graduate with a Interactive Media Design Diploma from Algonquin College in Ottawa, ON, in April 2024.",
  skills:
    "I possess a diverse skill set that encompasses web development, UX/UI design, and graphic design. I am proficient in programming languages such as HTML/CSS, JavaScript, and React. I have hands-on experience with content management systems like WordPress and possess expertise in creating wireframes and user interfaces. Additionally, I am skilled in graphic design and have a strong command of Adobe Creative Suite applications.",
  exploringNewHorizons:
    "As I approach graduation, I'm exploring new horizons in web design. I have developed a keen interest in creating captivating 3D effects for websites by integrating 3D Blender models and animations with technologies like WebGL and Three.js. This exciting journey allows me to push the boundaries of web design and offer users immersive and visually stunning online experiences.",

  education:
    "In addition to my current program, I hold a Master of Electrical Engineering which has equipped me with an engineering mindset and a range of transferable skills that complement my work in interactive media design. Through my studies, I have developed a systematic approach to problem-solving, an acute attention to detail, and the ability to effectively manage complex projects.",
  workExperience:
    "I have gained practical experience working as an IT Administrator at the Iranian Oil Pipeline and Telecommunication Company in Tehran, Iran. In this role, I ensured reliable network performance and provided training and mentorship to team members. ",
};
export default aboutMe;
