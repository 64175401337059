import { AiOutlineClose } from "react-icons/ai";
import { SkillsInformation } from "../data/SkillsInformation";
import { useSelector, useDispatch, Provider } from "react-redux";
import { setActiveSkill, setSkillIsSelected } from "../store/skillsSlice";
import store from "../store/store"; // Import your Redux store
import "./skillInformation.css";

const SkillInformationNo = () => {
  const dispatch = useDispatch();
  const activeSkill = useSelector((state) => state.skill.activeSkill);
  const skillIsSelected = useSelector((state) => state.skill.skillIsSelected);
  return (
    <div
      id="skill-information"
      className={`skill-div ${skillIsSelected ? "visible" : "invisible"}`}
    >
      <div className="skill">
        <img
          className="skill-img"
          src={SkillsInformation[activeSkill].img}
          alt=""
        />
        <div className="skill-content">
          <h2 className="skill-title">
            {SkillsInformation[activeSkill].title}
          </h2>
          <p className="skill-inf">{SkillsInformation[activeSkill].inf}</p>
        </div>
      </div>

      <button
        className="btn close-btn"
        onClick={(e) => {
          dispatch(setSkillIsSelected(false));
        }}
      >
        <AiOutlineClose />
      </button>
    </div>
  );
};

const SkillInformation = () => {
  return (
    <Provider store={store}>
      <SkillInformationNo />
    </Provider>
  );
};

export default SkillInformation;
