const photos = [
  {
    id: 0,
    img: "./images/projects/photos/pastil.jpg",
    title: "Micro Photography : sugers on a pastil",
    inf: "This is an instance from my creative photography project. There are more galley",
    url: "https://kaze0017b5c8.myportfolio.com/creative-photos",
  },
  {
    id: 1,
    img: "./images/projects/photos/storytelling.jpg",
    title: "Story telling : The artist",
    inf: "Photos can narraite a story. Check the whole story im my gallery",
    url: "https://kaze0017b5c8.myportfolio.com/photo-essay",
  },
  {
    id: 2,
    img: "./images/projects/photos/rules.jpg",
    title: "Breaking The Rules ",
    inf: "Rules are great, however breaking them might create more attractive photoes",
    url: "https://kaze0017b5c8.myportfolio.com/work",
  },
  {
    id: 3,
    img: "./images/projects/photos/motherspider.jpg",
    title: "Creative Photography: Mother Statue",
    inf: "Looking frome another angle might reveal other aspects of beauty",
    url: "https://kaze0017b5c8.myportfolio.com/creative-photos",
  },
  {
    id: 4,
    img: "./images/projects/photos/portrait.jpg",
    title: "Portrait",
    inf: "Different combination of shadows, lights, and color convey variety of emotions",
    url: "https://kaze0017b5c8.myportfolio.com/portrait",
  },
];
export default photos;
