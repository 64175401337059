import React, { useState, useEffect } from "react";
import { AiOutlineClose, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import arts from "../data/arts";

function ProjectsArtsInformation() {
  const [data, setData] = useState(arts);
  const [index, setIndex] = useState(0);

  const nextSlide = () => {
    setIndex((oldIndex) => {
      let index = oldIndex + 1;
      if (index > data.length - 1) {
        index = 0;
      }
      return index;
    });
  };
  const prevSlide = () => {
    setIndex((oldIndex) => {
      let index = oldIndex - 1;
      if (index < 0) {
        index = data.length - 1;
      }
      return index;
    });
  };
  useEffect(() => {
    let slider = setInterval(() => {
      setIndex((oldIndex) => {
        let index = oldIndex + 1;
        if (index > data.length - 1) {
          index = 0;
        }
        return index;
      });
    }, 5000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);

  return (
    <div id="artsInformation" className="projects-div invisible">
      {data.map((art, artIndex) => {
        const { title, inf, img } = art;
        let position = "nextSlide";
        if (artIndex === index) {
          position = "activeSlide";
        }
        if (
          artIndex === index - 1 ||
          (index === 0 && artIndex === data.length - 1)
        ) {
          position = "lastSlide";
        }
        return (
          <div key={artIndex} className={`slidshow ${position}`}>
            <img src={img} className="slidshow-image" />
            <h2 className="slideshow-title">{title}</h2>
            <p className="slideshow-inf">{inf}</p>
          </div>
        );
      })}
      <button className="btn next-btn" onClick={nextSlide}>
        <AiOutlineRight />
      </button>
      <button className="btn prev-btn" onClick={prevSlide}>
        <AiOutlineLeft />
      </button>
      <button
        className="close-btn"
        onClick={() => {
          document.querySelector(`#artsInformation`).classList.add("invisible");
        }}
      >
        <AiOutlineClose />
      </button>
    </div>
  );
}
export default ProjectsArtsInformation;
