const arts = [
  {
    id: 0,
    title: "Avatar",
    img: "./images/projects/arts/avatar.jpg",
    inf: "These avatars have been created using Adobe Illustrator. All elements are created and modified inside the application. Each avatar is proper for a different screen size. Using gradient filters has made these avatars so lovely",
  },
  {
    id: 1,
    title: "Poster",
    img: "./images/projects/arts/poster.jpg",
    inf: "A poster for a fictional horror movie. This poster is created using Adobe Photoshop. This project aimed to use photoshop features as much as possible. I used different kinds of brushes, filters, and layers to create this poster. This is one of my favourite artworks.",
  },
];
export default arts;
