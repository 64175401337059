// src/store/store.js

import { configureStore } from "@reduxjs/toolkit";
import skillsReducer from "./skillsSlice";
import cameraSlice from "./cameraSlice";
import navigationSlice from "./navigationSlice";
import aboutmeSlice from "./aboutmeSlice";

const store = configureStore({
  reducer: {
    skill: skillsReducer,
    camera: cameraSlice,
    navigation: navigationSlice,
    aboutme: aboutmeSlice,
  },
});

export default store;
