import React from "react";
import { AiOutlineHtml5 } from "react-icons/ai";
import { FaCss3, FaReact } from "react-icons/fa";
import { SiThreedotjs } from "react-icons/si";
import { Html } from "@react-three/drei";
import "./landing.css";
import { useSelector } from "react-redux";
const Landing = () => {
  const loading = useSelector((state) => state.camera.loading);
  return (
    <Html
      fullscreen
      className={`${loading === "false" ? "invisible" : "visible"}`}
    >
      <div className="landingPage">
        <h1>Hi! This is Keivan.</h1>
        <h2>Welcom to my page</h2>
        <div className="option">
          <h4>3D</h4>
          <p>React Three Fiber</p>
          <p className="icons">
            <FaReact />
            <SiThreedotjs />
          </p>
        </div>
        <div className="loader circle"></div>
      </div>
      {/* <div className="landing loadingPage">
        <div className="landing-center">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div id="typing-effect">
              <span className="line first-line"></span>
              <br />
              <span className="line second-line"></span>
            </div>
          </div>
          <div className="options">
            <a
              href="http://portfolio.keivanart.com"
              className="option"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4>Classic</h4>
              <p>html/css</p>
              <p className="icons">
                <AiOutlineHtml5 />
                <FaCss3 />
              </p>
            </a>
            <div className="option">
              <h4>3D</h4>
              <p>React Three Fiber</p>
              <p className="icons">
                <FaReact />
                <SiThreedotjs />
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </Html>
  );
};

export default Landing;
