// src/store/skillsSlice.js

import { createSlice } from "@reduxjs/toolkit";

const skillsSlice = createSlice({
  name: "skill",
  initialState: {
    activeSkill: 0,
    skillIsSelected: false,
  },
  reducers: {
    setActiveSkill: (state, action) => {
      state.activeSkill = action.payload;
    },
    setSkillIsSelected: (state, action) => {
      state.skillIsSelected = action.payload;
    },
  },
});

export const { setActiveSkill, setSkillIsSelected } = skillsSlice.actions;

export default skillsSlice.reducer;
