const SkillsInformation = [
  {
    id: 0,
    title: "Photography",
    inf: `A single image has the remarkable ability to speak volumes and evoke emotions without the need for words. When it comes to website development, photography plays a crucial role in capturing attention and telling compelling stories. Through my skilled eye for composition, lighting, and visual storytelling, I create captivating photographs that convey messages, showcase products, and reflect the essence of a brand or experience. By leveraging the power of imagery, I aim to create a visually engaging and immersive user experience that resonates with website visitors.`,
    img: "./images/skills/photography.jpg",
  },
  {
    id: 1,
    title: "Videography",
    inf: `Videos possess a unique power to engage and captivate audiences, making them an essential element in website development. With the ability to convey narratives, demonstrate products, or share memorable experiences, videos add dynamism and depth to a website. Through my expertise in videography, I skillfully capture and frame moments, ensuring that each video element aligns with the website's purpose and engages viewers. By leveraging techniques such as composition, storytelling, and visual effects, I create videos that leave a lasting impact, enhance user engagement, and bring the website's content to life in a compelling and immersive manner.`,
    img: "./images/skills/videography.jpg",
  },
  {
    id: 2,
    title: "Creativebrief",
    inf: `A well-defined creative brief serves as a roadmap for website design and development. By understanding project objectives, target audience, and brand guidelines, I establish a clear direction and ensure all stakeholders are aligned. Through thorough research and analysis, I craft comprehensive creative briefs that guide the design process and ensure the website effectively communicates its intended message.`,
    img: "./images/skills/creativebrief.jpg",
  },
  {
    id: 3,
    title: "Persona",
    inf: `Understanding the target audience is essential for creating user-centric websites. By developing user personas, I gain insights into their needs, preferences, and behaviors. This enables me to tailor the website's design and user experience to meet their specific requirements. Through in-depth research and data analysis, I create detailed personas that serve as a foundation for designing websites that resonate with the intended users.`,
    img: "./images/skills/persona.jpg",
  },
  {
    id: 4,
    title: "Moodboard",
    inf: `Moodboards are powerful tools for visualizing the desired look and feel of a website. By curating images, colors, typography samples, and design elements, I establish a cohesive visual direction that aligns with the client's vision. Moodboards provide inspiration and serve as a reference throughout the design process, ensuring consistency and conveying the intended mood and aesthetic of the website`,
    img: "./images/skills/moodboard.jpg",
  },
  {
    id: 5,
    title: "Web Development",
    inf: `: While websites can be created using pure HTML/CSS code, leveraging frameworks such as React, Three.js, WordPress, and Shopify allows for the development of more interactive and complex websites. With experience in these popular frameworks, I create dynamic websites that integrate design elements seamlessly, offer intuitive user interfaces, and provide engaging interactive features. By harnessing the power of these frameworks, I ensure that the websites I develop are visually appealing, technically sound, and tailored to meet the unique requirements of clients and their target audience.`,
    img: "./images/skills/webdevelopment.jpg",
  },
  {
    id: 6,
    title: "Content",
    inf: `Enhancing the content of a project contributes to the efficiency of the project. Proficiency in different related software is the key to achieving this. I enthusiastically have practiced popular software such as Adobe Creative Suits Apps and blender. This portfolio is made by React Three Fiber and 3D Blender models.`,
    img: "./images/skills/content.jpg",
  },
  {
    id: 7,
    title: "Websites",
    inf: `The final product of web development would be a website. Depending on what framework has been used, It can be a single-page app such as Facebook, or a multi-page website. During the past two years I have made different sites using different methods and some of them are provided in the project section `,
    img: "./images/skills/websites.png",
  },
  {
    id: 8,
    title: "Videos",
    inf: `The videos I have created are less than 3 minutes which makes them proper for most trending social media like TikTok, Instagram, and youtube . It was important for me to improve my skills in this field and learn how to work with applications such as Adobe Premiere. Some of my related works are provided in the project section`,
    img: "./images/skills/videos.png",
  },
  {
    id: 9,
    title: "Images",
    inf: `Portrait, Posters, Logos, Photo Essay and many other topics are the subjects of my artwork. Using the techniques and proper editing software has enabled me to create some appealing images. Some of my works are presented in the project section `,
    img: "./images/skills/images.png",
  },
];
export { SkillsInformation };
