import React, { useState, useEffect } from "react";
import { AiOutlineClose, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import photos from "../data/photos";

function ProjectsPhotosInformation() {
  const [data, setData] = useState(photos);
  const [index, setIndex] = useState(0);

  const nextSlide = () => {
    setIndex((oldIndex) => {
      let index = oldIndex + 1;
      if (index > data.length - 1) {
        index = 0;
      }
      return index;
    });
  };
  const prevSlide = () => {
    setIndex((oldIndex) => {
      let index = oldIndex - 1;
      if (index < 0) {
        index = data.length - 1;
      }
      return index;
    });
  };
  useEffect(() => {
    let slider = setInterval(() => {
      setIndex((oldIndex) => {
        let index = oldIndex + 1;
        if (index > data.length - 1) {
          index = 0;
        }
        return index;
      });
    }, 5000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);

  return (
    <div id="photosInformation" className="projects-div invisible">
      {data.map((photo, photoIndex) => {
        const { id, title, inf, img, url } = photo;
        let position = "nextSlide";
        if (photoIndex === index) {
          position = "activeSlide";
        }
        if (
          photoIndex === index - 1 ||
          (index === 0 && photoIndex === data.length - 1)
        ) {
          position = "lastSlide";
        }
        return (
          <div key={photoIndex} className={`slidshow ${position}`}>
            <img src={img} className="slidshow-image" />
            <h2 className="slideshow-title">{title}</h2>
            <p className="slideshow-inf">{inf}</p>
            <a className="slideshow-url" href={url} target="_blank">
              Visit the website
            </a>
          </div>
        );
      })}
      <button className="btn next-btn" onClick={nextSlide}>
        <AiOutlineRight />
      </button>
      <button className="btn prev-btn" onClick={prevSlide}>
        <AiOutlineLeft />
      </button>
      <button
        className="close-btn"
        onClick={() => {
          document
            .querySelector(`#photosInformation`)
            .classList.add("invisible");
        }}
      >
        <AiOutlineClose />
      </button>
    </div>
  );
}
export default ProjectsPhotosInformation;
