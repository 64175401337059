import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import App from "./app";
import {
  Navigation,
  ProjectsWebsitesInformation,
  ProjectsPhotosInformation,
  ProjectsArtsInformation,
  AboutMeInformation,
} from "./components";
import { useSelector, Provider } from "react-redux";
import store from "./store/store";
import { Landing } from "./components";

const ReactApp = () => {
  const background = "#101231";
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  const cameraPosition = useSelector((state) => state.camera.cameraPosition);
  return (
    <Provider store={store}>
      {/* Create the 3D canvas using @react-three/fiber */}
      <Canvas
        aria-setsize={[sizes.width, sizes.height]}
        camera={{ fov: 50, position: cameraPosition }}
      >
        <color attach="background" args={[background]} />
        {/* <Landing /> */}
        {/* Display a loading fallback component while content is being loaded */}
        <Suspense fallback={<Landing />}>
          {/* Render the main application component */}
          <App />
        </Suspense>
      </Canvas>
      {/* Display the navigation component - Hidden by default*/}
      <Navigation />

      {/* Display the information overlay for projects (websites, photos, arts) - hidden by default*/}
      <ProjectsWebsitesInformation />
      <ProjectsPhotosInformation />
      <ProjectsArtsInformation />
      <AboutMeInformation />
    </Provider>
  );
};
export default ReactApp;
