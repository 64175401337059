const websites = [
  {
    id: 0,
    title: "Portfolio",
    inf: "This website, created as the final project for the Web Development II course, showcases my creativity and skills in CSS and HTML. With a focus on vibrant SVG images and captivating CSS effects, I designed and developed a visually appealing website with four unique pages: Home, Services, Projects, and About. Leveraging various publicly available CSS libraries, such as Bootstrap, and incorporating JavaScript, I brought the project to life. Through a combination of HTML, CSS, JavaScript, and SVGs, I successfully achieved the project's goal of creating an engaging and dynamic web experience. I am excited to share my portfolio on GitHub, where visitors can explore my work and witness my proficiency in web development and my ability to utilize SVGs to enhance user experiences.",
    img: "./images/projects/websites/portfolio.jpg",
    url: "https://portfolio.keivanart.com/",
  },

  {
    id: 1,
    title: "Persian Kitchen",
    inf: " A Custom WordPress Website: Persian Kitchen is a bespoke WordPress website meticulously crafted from the ground up, featuring a tailor-made template hand-coded using HTML, CSS, JavaScript, and PHP. This project showcases my expertise in front-end and back-end web development. Notably, the integration of powerful plugins like Pods enhances the site's manageability, empowering users to easily update and maintain content. Explore this project to witness a harmonious blend of custom design and efficient content management within the WordPress framework.",
    img: "./images/projects/websites/persiankitchen.jpg",
    url: "https://dev-kaze0017.pantheonsite.io/",
  },
  {
    id: 2,
    title: "WheatScent",
    inf: "This website was designed and developed using pure CSS and HTML for an imaginary bakery. The main focus of this project was to create a mobile-friendly and responsive website that showcases the bakery's offerings. Through the use of CSS, I incorporated visually appealing designs, optimized image placement, and implemented mobile-responsive layouts to ensure a seamless experience across devices. By leveraging CSS techniques, such as flexbox and media queries, I successfully achieved a visually appealing and user-friendly website that highlights my skills in mobile design and image manipulation using CSS.",
    img: "./images/projects/websites/wheatscent.jpg",
    url: "https://wheatscent.keivanart.com/",
  },
  {
    id: 3,
    title: "SusAart - shopify",
    inf: "This Shopify website was built as a showcase of my e-commerce development skills. It was created as part of my web development journey, focusing on leveraging the features and capabilities of the Shopify platform. The website features a visually appealing design, seamless product browsing, and a secure checkout process. With custom product pages, responsive layouts, and integrated payment gateways, this website highlights my proficiency in setting up and customizing Shopify stores to provide a smooth and engaging online shopping experience.",
    img: "./images/projects/websites/susaart.jpg",
    url: "https://keivanimd.myshopify.com/",
  },
  {
    id: 4,
    title: "SusAart - 3D Version",
    inf: "This portfolio is a captivating website that showcases the exquisite artwork of a traditional Persian painter. Using React-Three-Fiber, the project creates an immersive 3D environment, allowing visitors to explore the painter's collection from unique angles and perspectives. With meticulous attention to detail and dynamic lighting effects, the website brings the paintings to life, offering a truly mesmerizing experience. Through the seamless fusion of technology and art, this project creates an appealing and artistic platform that celebrates the rich cultural heritage of Persian traditional painting.",
    img: "./images/projects/websites/susaart3d.jpg",
    url: "https://susaart.keivanart.com/",
  },
  {
    id: 5,
    title: "Showcase - WebGL - Blender",
    inf: "This website is a showcase of my mini projects that I have undertaken to explore the world of Blender and shaders in order to create effective effects. It is an ongoing project in which I am going to add my works.",
    img: "./images/projects/websites/showcase.jpg",
    url: "https://showcase.keivanart.com/",
  },
];
export default websites;
