import { createSlice } from "@reduxjs/toolkit";

const aboutmeSlice = createSlice({
  name: "navigation",
  initialState: {
    visibility: "invisible",
  },
  reducers: {
    setAboutmeVisibility: (state, action) => {
      state.visibility = action.payload;
    },
  },
});

export const { setAboutmeVisibility } = aboutmeSlice.actions;

export default aboutmeSlice.reducer;
